import React from 'react'
import { useState, useEffect } from 'react'

const AppContainer = ({children}) => {

    // Size detection
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion])

    return (
        <div className='app fading' style={{height: windowDimenion.winHeight - 64}}>
            {children}
        </div>
    )
}

export default AppContainer