import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
// Conponents
import Card from '../../../Components/Card';
// Material
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// Icons
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';



const TimerReports = ({ id }) => {

    const [dateFrom, setDateFrom] = useState(moment().startOf('month').format('yyyy-MM-DD'))
    const [dateTo, setDateTo] = useState(moment().endOf('month').format('yyyy-MM-DD'))

    useEffect(() => {
        loadPaymentMethod()
    }, [])

    const [printedData, setPrintedData] = useState([])
    const [printedDataInfo, setPrintedDataInfo] = useState([])
    const [totalObject, setTotalObject] = useState([])

    const componentRef = useRef()
    const toPrint = async () => {
        if (beforePrint()) {
            setTimeout(print, 500);
        }
    }

    const beforePrint = () => {
        if (!dateFrom) {
            console.log("Select Date From")
            return false
        }
        if (!dateTo) {
            console.log("Select Date To")
            return false
        }
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/reports/api_get_reports.php`, {
            params: {
                user_ID: id,
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPrintedData(response.data)
        })
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/reports/api_get_reports_timer_info.php`, {
            params: {
                user_ID: id,
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPrintedDataInfo(response.data)
        })
        return true
    }

    const print = useReactToPrint({
        content: () => componentRef.current,
    });

    const loadPaymentMethod = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/get_payment_method.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                setTotalObject(totalObject => [...totalObject, {
                    id: response.data[i].timer_note_payment_ID,
                    name: response.data[i].timer_note_payment_title,
                    total: []
                }])
            }
        })
    }

    let totalCost = []


    const resetTotal = () => {
        totalCost = []
        for (let i = 0; i < totalObject.length; i++) {
            totalObject[i].total = []
        }
    }

    return (
        <div>
            <div style={{display: 'none'}}>
                <div ref={componentRef} style={{ backgroundColor: 'white', padding: '20px', boxSizing: 'border-box' }}>
                    <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between' }}>
                        <p>Selected Dates: {moment(dateFrom).format('D/MM/yyyy')} - {moment(dateTo).format('D/MM/yyyy')} </p>
                        <p>{moment().format('D/MM/yyyy')}</p>
                    </div>
                    {printedData.map((val, index) => {
                        return (
                            <div key={index} style={{ marginBottom: 40 }}>
                                <div style={{ lineHeight: 2 }}>
                                    <p>Date: {val.date ? <span>{moment(val.date).format('D/MM/yyyy hh:mm')}</span> : null} </p>
                                    {val.title ? <p>title: <span>{val.title}</span></p> : null}
                                    {val.description ? <p>Note: <span>{val.description}</span></p> : null}
                                </div>
                                <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
                                    <thead style={{}}>
                                        <tr >
                                            <th style={{ borderBottom: '1px solid #f2f2f2', padding: '8px 0' }}><p>Cost</p></th>
                                            <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Payment Method</p></th>
                                            <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Payment For</p></th>
                                            <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Note</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {printedDataInfo.filter(row => row.timer_ID === val.timer_ID).map((v, i) => {
                                            totalCost = [...totalCost, parseInt(v.timer_info_cost)]

                                            let objIndex = totalObject.findIndex((obj => obj.id === v.timer_info_payment));
                                            totalObject[objIndex].total = [...totalObject[objIndex].total, parseInt(v.timer_info_cost)]

                                            return (
                                                <tr key={i}>
                                                    <td><p>{v.timer_info_cost} €</p></td>
                                                    <td><p>{v.timer_note_payment_title}</p></td>
                                                    <td><p>{v.timer_note_types_title}</p></td>
                                                    <td><p>{v.timer_info_desc}</p></td>
                                                </tr>
                                            )
                                        })}

                                        <tr style={{ borderTop: '1px solid #fcfcfc' }}>
                                            <td style={{ padding: '8px 0' }}><p>Total: {totalCost.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td>
                                            <td style={{ padding: '8px 0' }}>
                                                {totalObject.map((val, i) => {
                                                    return <p key={i}>Total {val.name}:  {val.total.reduce(function (acc, val) { return acc + val; }, 0)}</p>

                                                })}
                                            </td>
                                        </tr>
                                        {resetTotal()}
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}

                </div>
            </div>

            <div  >
                <Card
                    title="Timer Report Settings"
                    radius={2}
                    mb={24}
                    fullWidth
                >
                    <TextField style={{ marginBottom: 16 }} defaultValue={dateFrom} fullWidth type="date" label="Outlined" variant="outlined" onChange={(e) => setDateFrom(e.target.value)} />
                    <TextField style={{ marginBottom: 16 }} defaultValue={dateTo} fullWidth type="date" label="Outlined" variant="outlined" onChange={(e) => setDateTo(e.target.value)} />
                    <Button variant="contained" onClick={toPrint} endIcon={<LocalPrintshopIcon />} fullWidth>Print</Button>
                </Card>
            </div>
        </div>
    )
}

export default TimerReports