import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
// Components
import AppContainer from '../../Components/AppContainer'
import Card from '../../Components/Card';
// Materail
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
// Icnos
import DeleteIcon from '@mui/icons-material/Delete';

const Todo = ({ authorized, id }) => {

    const navigate = useNavigate();

    let formData = new FormData();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        loadTodo()
        setHeaderTitle("Todo")
        document.title = 'Usedtruck | Todo';
        setHeaderLinkTo('')

    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])


    const [toDoTitle, setToDoTitle] = useState("")
    const [toDoDate, setToDoDate] = useState(moment().format('yyyy-MM-D'))
    const [toDoFilterDate, setToDoFilterDate] = useState(moment().format('yyyy-MM-D'))
    const [todoData, setTodoData] = useState([])
    const [todoDataFilterd, setTodoDataFilterd] = useState([])

    const loadTodo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/todo/api_get_todo.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTodoData(response.data)
            setTodoDataFilterd(response.data.filter(row => (row.dateToDo.toLowerCase().indexOf(toDoFilterDate.toLowerCase()) > -1)))
        })
    }

    const addToDo = () => {

        formData.append('title', toDoTitle)
        formData.append('date', toDoDate)
        formData.append('user_ID', id)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/todo/api_post_todo.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setToDoTitle("")
        }).catch(function (response) {
            console.log(response);
        });
    }

    const deleteTodo = (ToDoIdDe) => {

        formData.append('id', ToDoIdDe)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/todo/api_delete_todo.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTodo()
            viewTodoList()
        }).catch(function (response) {
            console.log(response);
        });
    }

    const viewTodoList = () => {
        setTodoDataFilterd(todoData.filter(row => (row.dateToDo.toLowerCase().indexOf(toDoFilterDate.toLowerCase()) > -1)))
    }

    const toDoStatusChange = (ToDoId, status) => {

        formData.append('status', status)
        formData.append('id', ToDoId)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/todo/api_update_todo_status.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTodo()
            viewTodoList()
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <AppContainer>
            <div className='padding'>

                <div  >
                    <Card
                        title="Add new task to do"
                        radius={2}
                        mb={24}
                    >
                        <TextField fullWidth style={{ marginBottom: 16 }} value={toDoTitle} label="Title" variant="outlined" onChange={(e) => setToDoTitle(e.target.value)} />
                        <TextField fullWidth style={{ marginBottom: 16 }} defaultValue={toDoDate} label="Date To do" type="date" variant="outlined" onChange={(e) => setToDoDate(e.target.value)} />
                        <Button variant="contained" fullWidth onClick={addToDo}>Add</Button>
                    </Card>
                    <Card
                        title="View to do tasks"
                        radius={2}
                    >
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <TextField fullWidth style={{ marginBottom: 16 }} defaultValue={toDoFilterDate} type="date" label="Title" variant="outlined" onChange={(e) => setToDoFilterDate(e.target.value)} />
                            <Button variant="contained" style={{ height: '56px', marginLeft: 16 }} onClick={viewTodoList} >View</Button>

                        </div>
                        <div className='small-table' style={{ marginTop: 40 }}>
                            {todoDataFilterd.length !== 0 ? todoDataFilterd.map((val, i) => {
                                return (
                                    <div className='small-table-row' key={i}>
                                        <p>{val.title ? val.title : '(No title)'}</p>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div>{val.status === '1' ? <Chip label="Done" onClick={() => toDoStatusChange(val.todo_ID, 0)} color="primary" /> : <Chip label="Not yet" color="error" onClick={() => toDoStatusChange(val.todo_ID, 1)} />}</div>
                                            <IconButton aria-label="delete" style={{marginLeft: 16}} onClick={() => deleteTodo(val.todo_ID)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }) : <p style={{ textAlign: 'center' }}>No Result</p>}
                        </div>
                    </Card>
                </div>
            </div>
        </AppContainer>
    )
}

export default Todo