import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import axios from 'axios';
// Materail
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
// Icnos
import DeleteIcon from '@mui/icons-material/Delete';
// Components
import Card from '../../Components/Card';

const ToDoDashboard = ({ id }) => {

    let formData = new FormData();

    const [todoDataFilterd, setTodoDataFilterd] = useState([])


    useEffect(() => {
        loadTodo()
    }, [])

    const loadTodo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/todo/api_get_todo.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTodoDataFilterd(response.data.filter(row => (row.dateToDo.toLowerCase().indexOf(moment().format('yyyy-MM-D').toLowerCase()) > -1)))
        })
    }

    const deleteTodo = (id) => {

    }

    const toDoStatusChange = (ToDoId, status) => {

        formData.append('status', status)
        formData.append('id', ToDoId)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/todo/api_update_todo_status.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTodo()
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <Card
            title="To do today"
            radius={2}
        >
            <div className='small-table' >
                {todoDataFilterd.length !== 0 ? todoDataFilterd.map((val, i) => {
                    return (
                        <div className='small-table-row' key={i}>
                            <p>{val.title ? val.title : '(No title)'}</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{val.status === '1' ? <Chip label="Done" onClick={() => toDoStatusChange(val.todo_ID, 0)} color="primary" /> : <Chip label="Not yet" color="error" onClick={() => toDoStatusChange(val.todo_ID, 1)} />}</div>
                                <IconButton aria-label="delete" style={{ marginLeft: 16 }} onClick={() => deleteTodo(val.todo_ID)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    )
                }) : <p style={{ textAlign: 'center' }}>Nn Result</p>}
            </div>
        </Card>
    )
}

export default ToDoDashboard