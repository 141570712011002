import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";


let theme = createTheme({
    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
             
                    "&.Mui-selected .MuiListItemText-root": {
                        color: "#6393d9",
                    },
                    "&.Mui-selected svg": {
                        color: "#6393d9"
                    },
                    "&.MuiListItemButton-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.0)",
                    },
                    "&.MuiListItemButton-root:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)"
                    },
                    "svg": {
                        color: "#fff"
                    },
                    color: '#fff',
                    
                },
            }
        }
    },
    palette: {
        primary: {
            main: '#6393d9',
        },
        secondary: {
            light: '#e74c49',
            main: '#6393d9',
            contrastText: '#ffffff',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <Router>
            <App />
        </Router>
    </ThemeProvider>
);

