import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';

// Material
import Button from '@mui/material/Button';
// Components
import AppContainer from '../../Components/AppContainer'
// Dashboard Components
import ToDoDashboard from './ToDoDashboard';


const Dashboard = ({ authorized, id }) => {

    const navigate = useNavigate();
    const [timer, setTimer] = useState(false)
    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Dashboard")
        checkActive()
        document.title = 'Usedtruck | Dashboard';
        setHeaderLinkTo('')
    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])

    const checkActive = async () => {
        try {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_check_active.php`, {
                params: {
                    user_ID: id
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.length === 0) {
                    setTimer(true)
                } else {
                    setTimer(false)
                }
            }).catch(error => console.log(error));;

        } catch (e) {
            console.log(e)
        }
    }

    const goToActiveTimer = () => {
        navigate('/timer/active')
    }

    return (
        <AppContainer>

            <div className='padding'>
                <Button disabled={timer} variant="contained" fullWidth style={{marginBottom: 16, marginTop: 8}} onClick={goToActiveTimer} >Go To Active Timer</Button>
                <ToDoDashboard id={id} />
            </div>
        </AppContainer>
    )
}

export default Dashboard