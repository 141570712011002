import React from 'react'
import '../style.css'

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const AddedItems = ({ note, paytype, payto, cost, onClick }) => {



    return (
        <div className='timer-added-items'>
            <div>
                {note ? <p>Note: <span>{note}</span></p> : null}
                {paytype ? <p>Payment method: <span>{paytype}</span></p> : null}
                {payto ? <p>Payment for: <span>{payto}</span></p> : null}
                {cost ? <p>Price: <span>{cost}</span>€</p> : null}
            </div>
            <div>
                <IconButton aria-label="delete" onClick={onClick}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    )
}

export default AddedItems