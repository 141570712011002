import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
// Components
import AppContainer from '../../Components/AppContainer'
// Material
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Fab from '@mui/material/Fab';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Icons
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const Timer = ({ authorized, id }) => {

    const navigate = useNavigate();
    let formData = new FormData();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Timers")
        document.title = 'Usedtruck | Active timer';
        setHeaderLinkTo('')
    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])


    const [deleteDialop, setdeleteDialop] = useState(false);
    const dialogClose = () => {
        setdeleteDialop(false);
    };
    const [selectedID, setSelectedID] = useState(0);
    const deleteDate = (id) => {
        setdeleteDialop(true);
        setSelectedID(id)
    }

    const [search, setSearch] = useState("")


    const [load, setLoad] = useState(false)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [timer, setTimer] = useState(false)




    useEffect(() => {
        loadData(search)
        setColumns([
            {
                field: 'title',
                headerName: 'title',
                flex: 1,
                editable: false,
            },
            {
                field: 'date',
                headerName: 'date',
                flex: 1,
                editable: false,
            },
            {
                field: 'facility_ID',
                renderCell: (cellValues) => {
                    return (
                        <div>
                            {cellValues.row.status === "1"
                                ?
                                <Tooltip title="Delete">
                                    <IconButton aria-label="delete" size="small" onClick={() => navigate(`/timer/active`)} >
                                        <NavigateNextIcon />
                                    </IconButton>
                                </Tooltip>
                                :
                                <div>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete" size="small" onClick={() => deleteDate(cellValues.row.timer_ID)} >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete" size="small" onClick={() => navigate(`/timer/${cellValues.row.timer_ID}`)} >
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            }
                        </div>
                    )
                },
                headerName: '',
                flex: 1,
                editable: false,
                type: 'number'
            },

        ])
        setLoad(true)
    }, [search, navigate])





    const loadData = (search) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_get_dates.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (search.length > 0) {
                setRows(response.data.filter(row => (row.date.toLowerCase().indexOf(search.toLowerCase()) > -1 || row.title.toLowerCase().indexOf(search.toLowerCase()) > -1 || row.description.toLowerCase().indexOf(search.toLowerCase()) > -1 )))
            } else {
                setRows(response.data)
            }
            checkActive()
        })
    }


    const checkActive = async () => {
        try {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_check_active.php`, {
                params: {
                    user_ID: id
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.length === 0) {
                    setTimer(false)
                } else {
                    setTimer(true)
                }
            }).catch(error => console.log(error));;

        } catch (e) {
            // error reading value
            console.log(e)
        }
    }


    const startTimeHandle = () => {
        setTimer(!timer)
        if (!timer) {
            var dateObj = new Date();
            var month = dateObj.getUTCMonth() + 1;
            var day = dateObj.getUTCDate();
            var year = dateObj.getUTCFullYear();
            var hour = dateObj.getUTCHours();
            var minute = dateObj.getUTCMinutes();
            var second = dateObj.getUTCSeconds();
            let today = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;


            formData.append('date', today)
            formData.append('status', 1)
            formData.append('user_ID', id)

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_post_new_day.php`,
                data: formData,
                headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
            }).then(function (response) {
                loadData(search)
            }).catch(function (response) {
                console.log(response);
            });


        } else {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_update_inactive.php`, {
                params: {
                    user_ID: id
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                loadData(search)
            }).catch(error => console.log(error));;
        }
    }


    const confirmDelete = (id) => {
        setdeleteDialop(false)
        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_delete_date.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadData(search)
        }).catch(function (response) {
            console.log(response);
        });
    }


    return (
        <AppContainer>
            <div className='table-search-bar'>
                <TextField  fullWidth label="Search" variant="standard" onChange={(e) => setSearch(e.target.value)} />
            </div>
            {load ?
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            }
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        getRowId={(rows) => rows.timer_ID}
                        rowsPerPageOptions={[50]}
                        disableSelectionOnClick
                        disableColumnMenu
                        disableCellFocusOutline
                        style={{height: 'calc(100% - 64px)'}}
                    />

                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color='secondary' />
                </Box>
            }
            <Fab color="secondary" aria-label="add" style={timer ? { backgroundColor: '#ff6f6f' } : null} className='float-action-button' onClick={startTimeHandle}>
                <TimerOutlinedIcon />
            </Fab>

            <Dialog open={deleteDialop} onClose={dialogClose} >
                <DialogTitle id="alert-dialog-title">
                    Do you want to delete this date?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you click yes, All related options like related will be deleted
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={dialogClose} color="error">No</Button>
                    <Button onClick={() => confirmDelete(selectedID)} color="secondary" autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>

        </AppContainer>
    )
}

export default Timer