import React, { useState, useEffect, useContext, useRef } from 'react'
import { HeaderContext } from '../../helper/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
// Components
import AppContainer from '../../Components/AppContainer';
import AddedItems from './Components/AddedItems';
// Materila
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// Icons
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
const TimerItem = ({ authorized, id }) => {

    const { iid } = useParams()
    const navigate = useNavigate();

    const [load, setLoad] = useState(false)
    // Header config
    const [timerDate, setTimerDate] = useState('')
    const [defauldDate, setDefauldDate] = useState('')

    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle(`Timer (${moment(timerDate).format('yyyy-MM-DD')})`)
        document.title = 'Usedtruck | Active timer';
        setHeaderLinkTo('/timer')
        loadData()
        loadTimerInfo()
        loadPaymentMethod()
        loadPaymentTo()
        setDefauldDate(moment(timerDate).format('yyyy-MM-DDThh:mm:ss'))

        setLoad(true)
    }, [timerDate, setHeaderTitle, setHeaderLinkTo, navigate])


    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentTo, setPaymentTo] = useState([])
    const [timerData, setTimerData] = useState([])
    const [timerInfo, setTimerInfo] = useState([])

    const [timerTitle, setTimerTitle] = useState('')
    const [timerNote, setTimerNote] = useState('')


    const [cost, setCost] = useState(0)
    const [note, setNote] = useState('')

    const [type, setType] = useState('');
    const selectType = (event) => {
        setType(event.target.value);
    };

    const [payment, setPayment] = useState('')
    const selectPayment = (event) => {
        setPayment(event.target.value);
    };

    const loadPaymentMethod = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/get_payment_method.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentMethods(response.data)
        })
    }

    const loadPaymentTo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentToOption/get_payment_to.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentTo(response.data)
        })
    }

    let formData = new FormData();


    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_get_dates.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTimerData(response.data.filter(row => row.timer_ID === iid)[0])
            setTimerTitle(response.data.filter(row => row.timer_ID === iid)[0].title)
            setTimerNote(response.data.filter(row => row.timer_ID === iid)[0].description)
            setTimerDate(response.data.filter(row => row.timer_ID === iid)[0].date)
        })
    }

    const onTimerDateChange = (e) => {

        formData.append('timer_ID', iid)
        formData.append('title', timerTitle)
        formData.append('note', timerNote)
        formData.append('date', e.target.value)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_update_date.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadData()
        }).catch(function (response) {
            console.log(response);
        });
    }

    const loadTimerInfo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_get_dates_info.php`, {
            params: {
                user_ID: id,
                timer_ID: iid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTimerInfo(response.data)
        })
    }

    const deleteItemInfo = (id) => {
        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_delete_date_info.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTimerInfo()
        }).catch(function (response) {
            console.log(response);
        });
    }

    const addItemInfo = () => {

        formData.append('cost', cost)
        formData.append('payto', payment)
        formData.append('paytype', type)
        formData.append('note', note)
        formData.append('timer_ID', iid)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_post_date_info.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTimerInfo()
            setCost(0)
            setNote('')
            setType('')
            setPayment('')
        }).catch(function (response) {
            console.log(response);
        });

    }

    const updateTimerInfo = () => {
        formData.append('timer_ID', iid)
        formData.append('title', timerTitle)
        formData.append('note', timerNote)
        formData.append('date', timerDate)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_update_date.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
        }).catch(function (response) {
            console.log(response);
        });

    }

    const componentRef = useRef();
    const toPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let today = year + "-" + month + "-" + day;


    let totalCost = []
    let totalCard = []
    let totalCash = []



    return (
        <AppContainer>
            {load ?
                <div className='fading padding'>
                    <div style={{display: 'none'}}>
                        <div ref={componentRef} style={{ width: '100%', padding: '40px 80px', boxSizing: 'border-box' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div></div>
                                <p>{today}</p>
                            </div>
                            <div>
                                <div style={{ lineHeight: 2 }}>
                                    <p>Date: <span>{timerData.date}</span></p>
                                    <p>title: <span>{timerTitle}</span></p>
                                    <p>Note: <span>{timerNote}</span></p>
                                </div>
                                <div>
                                    <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
                                        <thead style={{}}>
                                            <tr >
                                                <th style={{ borderBottom: '1px solid #f2f2f2', padding: '8px 0' }}><p>Cost</p></th>
                                                <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Payment Method</p></th>
                                                <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Payment For</p></th>
                                                <th style={{ borderBottom: '1px solid #f2f2f2' }}><p>Note</p></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {timerInfo.map((val, i) => {
                                                totalCost = [...totalCost, parseInt(val.timer_info_cost)]
                                                val.timer_info_payment === '2'
                                                    ? totalCard = [...totalCard, parseInt(val.timer_info_cost)]
                                                    : totalCash = [...totalCash, parseInt(val.timer_info_cost)]
                                                return (
                                                    <tr key={i}>
                                                        <td><p>{val.timer_info_cost} €</p></td>
                                                        <td><p>{val.timer_note_payment_title}</p></td>
                                                        <td><p>{val.timer_note_types_title}</p></td>
                                                        <td><p>{val.timer_info_desc}</p></td>
                                                    </tr>
                                                )
                                            })}

                                            <tr style={{ borderTop: '1px solid #fcfcfc' }}>
                                                <td style={{ padding: '8px 0' }}><p>Total: {totalCost.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td>
                                                <td style={{ padding: '8px 0' }}><p>Total Card: {totalCard.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td>
                                                <td style={{ padding: '8px 0' }}><p>Total Cash: {totalCash.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <TextField fullWidth style={{ marginBottom: 16 }} label="Price" variant="outlined" onChange={(e) => setCost(e.target.value)} type="number" value={cost} />
                    <TextField fullWidth style={{ marginBottom: 16 }} label="Note" variant="outlined" onChange={(e) => setNote(e.target.value)} />

                    <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={type}
                            label="Type"
                            onChange={selectType}
                        >
                            {paymentMethods.map((val, i) => {
                                return <MenuItem key={i} value={val.timer_note_payment_ID}>{val.timer_note_payment_title}</MenuItem>
                            })}
                        </Select>
                    </FormControl >

                    <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel>Pay to</InputLabel>
                        <Select
                            value={payment}
                            label="Pay to"
                            onChange={selectPayment}
                        >
                            {paymentTo.map((val, i) => {
                                return <MenuItem key={i} value={val.timer_note_types_ID}>{val.timer_note_types_title}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={() => addItemInfo()}>Add</Button>


                    <Divider style={{ marginBottom: 16, marginTop: 16 }}><p>Static</p></Divider>
                    <TextField value={defauldDate} fullWidth style={{ marginBottom: 16 }} type="dateTime-local" label="Date" variant="outlined" onChange={(e) => onTimerDateChange(e)} />
                    <TextField value={timerTitle} fullWidth style={{ marginBottom: 16 }} label="Title" variant="outlined" onChange={(e) => setTimerTitle(e.target.value)} onBlur={() => updateTimerInfo()} />
                    <TextField value={timerNote} fullWidth style={{ marginBottom: 16 }} label="Description" variant="outlined" multiline rows={4} onChange={(e) => setTimerNote(e.target.value)} onBlur={() => updateTimerInfo()} />
                    <Button variant="contained" color="success" endIcon={<LocalPrintshopIcon />} fullWidth onClick={toPrint}>Print</Button>

                    <Divider style={{ marginBottom: 16, marginTop: 16 }}><p>Added items</p></Divider>
                    {timerInfo.map((val, i) => {
                        return <AddedItems
                            key={i}
                            note={val.timer_info_desc}
                            paytype={val.timer_note_payment_title}
                            payto={val.timer_note_types_title}
                            cost={val.timer_info_cost}
                            onClick={() => deleteItemInfo(val.timer_info_ID)}
                        />
                    })}
                </div>
                : null}
        </AppContainer>
    )
}

export default TimerItem