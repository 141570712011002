import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
// Components
import LoginCard from './components/LoginCard'

const Login = ({ mobile, tablet }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const cookies = new Cookies();
    const token = cookies.get('jwt')
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        document.title = 'Prorec Bokning CMS | Login';
        if (token) {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login_verify.php`, {
                params: {
                    token: token
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.condition === false) {
                    navigate("/login");
                } else {
                    navigate("/");
                }
            })
        } else {
            navigate("/login");
        }
    }, [token, navigate])

    // Login to application function
    const loginSubmit = async () => {
        setErrorMessage()
        setLoading(true)
        try {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login.php`, {
                params: {
                    email: email,
                    password: password
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.user) {
                    cookies.set('jwt', response.data.token, { maxAge: response.data.maxAge });
                    axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-one-user.php`, {
                        params: {
                            id: response.data.user
                        },
                        headers: {
                            'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                        }
                    }).then((response) => {
                        localStorage.setItem("User", JSON.stringify({ "UUID": response.data[0].user_ID, "Name": response.data[0].user_name }));

                    })

                    navigate("/");
                } else if (response.data.error === "wrong email") {
                    setErrorMessage('Användaren hittades inte')
                    setLoading(false)
                } else if (response.data.error === "wrong password") {
                    setErrorMessage('Lösenordet matchar inte användarnamnet')
                    setLoading(false)
                } else {
                    setErrorMessage('Fel')
                    setLoading(false)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <LoginCard>
                <h1>Logga in</h1>
                <form>
                    <TextField onKeyDown={handleEnter} label="E-post *" fullWidth variant="outlined" style={{ marginBottom: 16 }} onChange={(e) => setEmail(e.target.value)} />
                    <TextField label="Lösenord *" type="password" fullWidth style={{ marginBottom: 16 }} variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                    <div className='right-button-container mb-16' style={{ marginBottom: 16 }}>
                        <div></div>
                        <Link to="/reset-password"><p>Glömt Lösenord?</p></Link>
                    </div>
                    <LoadingButton
                        onClick={loginSubmit}
                        loading={loading}
                        variant="contained"
                        fullWidth
                        style={{ marginBottom: 16 }}
                        type="submit"
                    >
                        Logga in
                    </LoadingButton>
                </form>
                <p className="copy-right-text">Copyright © {new Date().getFullYear()} Used Truck SRL. All rights reserved.</p>
            </LoginCard>

            {errorMessage ? <Alert icon={false} severity="success" style={{ position: 'absolute', bottom: 40, left: '50%', transform: 'translateX(-50%)', backgroundColor: '#fafafa', boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1)', transition: 'all 0.2s ease-in-out' }}> {errorMessage} </Alert> : <></>}
        </div>
    )
}

export default Login