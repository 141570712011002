import React from 'react'

const Main = ({children, asideActive}) => {
    return (
        <div className={asideActive ? 'main active' : 'main'}>
            {children}
        </div>
    )
}

export default Main