import { useState, useEffect } from 'react'
import { HeaderContext } from './helper/Context'
import { Routes, Route, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
// Layout
import Aside from './Components/Aside'
import Header from './Components/Header'
import Main from './Components/Main'
import AppContainer from './Components/AppContainer'
// Pages
import Dashboard from './Pages/Dashboard/Dashboard';
import Timer from './Pages/Timer/Timer';
import TimerActive from './Pages/Timer/TimerActive';
import TimerItem from './Pages/Timer/TimerItem';

import Reports from './Pages/Reports/Reports';

import Configuration from './Pages/Configuration/Configuration';

import Todo from './Pages/Todo/Todo';

import Login from './Pages/Login/Login';
import ResetPass from './Pages/Login/ResetPass';
import NewPass from './Pages/Login/NewPass';

const App = () => {

    const navigate = useNavigate();
    let UUID;

    if (localStorage.getItem("User") !== null) {
        let userObject = localStorage.getItem('User');
        UUID = JSON.parse(userObject).UUID;
    }
    // Provider attributes
    const [headerTitle, setHeaderTitle] = useState('')
    const [headerLinkTo, setHeaderLinkTo] = useState('')
    const [asideActive, setAsideActive] = useState(true)

    // Authrization
    const cookies = new Cookies();
    const token = cookies.get('jwt')
    const [authorized, setAuthorized] = useState(true)

    // Size detection
    const [mobileSize, setMobileSize] = useState(false)
    const [tabletSize, setTabletSize] = useState(false)
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    useEffect(() => {
        window.addEventListener('resize', detectSize)


        if (token) {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login_verify.php`, {
                params: {
                    token: token
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.condition === false) {
                    setAuthorized(false)
                } else {
                    setAuthorized(true)
                }
            })
        } else {
            setAuthorized(false)
        }

        if (windowDimenion.winWidth <= 1280) {
            setTabletSize(true)
            setAsideActive(false)
        } else if (windowDimenion.winWidth <= 600) {
            setAsideActive(false)
            setTabletSize(false)
            setMobileSize(true)
        } else {
            setAsideActive(true)
            setTabletSize(false)
            setMobileSize(false)
        }
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion, token, navigate])



    return (
        <HeaderContext.Provider value={{ headerTitle, setHeaderTitle, headerLinkTo, setHeaderLinkTo, asideActive, setAsideActive }}>
            <div className="container active" style={{ height: windowDimenion.winHeight }}>
                {authorized ? <Aside active={asideActive} mobile={mobileSize} tablet={tabletSize} /> : null}
                <Main aactive={authorized ? asideActive : false}>
                    {authorized ? <Header title={headerTitle} linkTo={headerLinkTo} /> : null}
                    <AppContainer authorized={authorized}>
                        <Routes>
                            <Route path="/" element={<Dashboard id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/dashboard" element={<Dashboard id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/timer" element={<Timer id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>
                            <Route path="/timer/active" element={<TimerActive id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>
                            <Route path="/timer/:iid" element={<TimerItem id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>

                            <Route path="/reports" element={<Reports id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>

                            <Route path="/configuration" element={<Configuration id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>
                            <Route path="/todo" element={<Todo id={UUID} mobile={mobileSize} tablet={tabletSize} authorized={authorized} />} exact></Route>

                            

                            <Route path="/login" element={<Login />} exact></Route>
                            <Route path="/reset-password" element={<ResetPass />} exact></Route>
                            <Route path="/change-password/:vkey" element={<NewPass />} exact></Route>

                        </Routes>
                    </AppContainer>
                </Main>
            </div>
        </HeaderContext.Provider>
    )
}

export default App