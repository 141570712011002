import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
// Components
import AppContainer from '../../Components/AppContainer'
import Card from '../../Components/Card';
// Materail
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

// Icnos
import DeleteIcon from '@mui/icons-material/Delete';

const Configuration = ({ authorized, id }) => {

    const navigate = useNavigate();

    let formData = new FormData();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        loadPaymentTo()
        loadPaymentMethod()
        setHeaderTitle("Reports")
        document.title = 'Usedtruck | Reports';
        setHeaderLinkTo('')

    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])

    const [paymentToOption, setPaymentToOption] = useState([])
    const [name, setName] = useState("")

    const [paymentMethodOption, setPaymentMethodOption] = useState([])
    const [paymentMethodName ,setPaymentMethodName] = useState("")
    const loadPaymentTo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentToOption/get_payment_to.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentToOption(response.data)
        })
    }

    const addPaymentToOption = () => {
        formData.append('name', name)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/paymentToOption/api_post_payment_to.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadPaymentTo()
            setName("")
        }).catch(function (response) {
            console.log(response);
        });

    }

    const deletePayToOption = (id) => {
        formData.append('id', id)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/paymentToOption/api_detele_payment_to.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadPaymentTo()
        }).catch(function (response) {
            console.log(response);
        });
    }


    const loadPaymentMethod = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/get_payment_method.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentMethodOption(response.data)
        })
    }

    const addPaymentMethodOption = () => {
        formData.append('name', paymentMethodName)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/api_post_payment_method.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadPaymentMethod()
            setPaymentMethodName("")
        }).catch(function (response) {
            console.log(response);
        });

    }

    const deletePayMethodOption = (id) => {
        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/api_detele_payment_method.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadPaymentMethod()
        }).catch(function (response) {
            console.log(response);
        });
    }
    return (
        <AppContainer>
            <div className='padding'>

                <div  >
                    <Card
                        title="Pay for config"
                        radius={2}
                        mb={24}
                    >
                        <TextField fullWidth style={{ marginBottom: 16 }} label="Option name" variant="outlined" onChange={(e) => setName(e.target.value)} />
                        <Button variant="contained" fullWidth onClick={addPaymentToOption}>Add</Button>

                        <div className='small-table' style={{ marginTop: 40 }}>
                            {paymentToOption.map((val, i) => {
                                return (
                                    <div className='small-table-row' key={i}>
                                        <p>{val.timer_note_types_title}</p>
                                        <IconButton aria-label="delete" onClick={() => deletePayToOption(val.timer_note_types_ID)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )
                            })}
                        </div>
                    </Card>

                    <Card
                        title="Payment method config"
                        radius={2}
                        mb={24}
                    >
                        <TextField fullWidth style={{ marginBottom: 16 }} label="Option name" variant="outlined" onChange={(e) => setPaymentMethodName(e.target.value)} />
                        <Button variant="contained" fullWidth onClick={addPaymentMethodOption}>Add</Button>

                        <div className='small-table' style={{ marginTop: 40 }}>
                            {paymentMethodOption.map((val, i) => {
                                return (
                                    <div className='small-table-row' key={i}>
                                        <p>{val.timer_note_payment_title}</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Switch defaultChecked />
                                            <IconButton aria-label="delete" onClick={() => deletePayMethodOption(val.timer_note_payment_ID)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Card>
                </div>
            </div>
        </AppContainer>
    )
}

export default Configuration