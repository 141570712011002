import React, { useState, useEffect, useContext } from 'react'
import logo from '../assets/logo.jpg'
import { Link } from "react-router-dom";
import { HeaderContext } from '../helper/Context';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
// Materail
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Icons
import HomeIcon from '@mui/icons-material/Home';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GradingIcon from '@mui/icons-material/Grading';

const Aside = ({ active, mobile, tablet }) => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    const { asideActive, setAsideActive } = useContext(HeaderContext)
    useEffect(() => {
        setAsideActive(active)
        // if(tablet || mobile){
        //     setAsideActive(false)
        // }
    }, [active, setAsideActive])


    const [catelogOpen, setCatelogOpen] = useState(false);


    const [menu, setMeny] = useState(1);
    const handleChange = (event) => {
        setMeny(event.target.value);
    };

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 1) {
            setCatelogOpen(!catelogOpen)
        }
        if (tablet || mobile) {
            setAsideActive(false)
        }
    };

    useEffect(() => {
        if (window.location.pathname.indexOf("/dashboard") !== -1) {
            setSelectedIndex(0)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/timer") !== -1) {
            setSelectedIndex(1)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/reports") !== -1) {
            setSelectedIndex(2)
            setMeny(1)
        }else if (window.location.pathname.indexOf("/todo") !== -1) {
            setSelectedIndex(3)
            setMeny(1)
        }else if (window.location.pathname.indexOf("/configuration") !== -1) {
            setSelectedIndex(10)
            setMeny(1)
        }
    }, [])


    const asideToggle = () => {
        setAsideActive(false)
    }




    const renderMenu = () => {
        if (menu === 1) {
            return (
                <Box sx={{ width: '100%', maxWidth: 320, bgcolor: '#333' }} >
                    <List component="nav" aria-label="Dashboard">
                        <Link to="/dashboard">
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}
                            >
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav" aria-label="Timer">
                        <Link to="/timer">
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <StoreMallDirectoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Timer" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav" aria-label="Reports">
                        <Link to="/reports">
                            <ListItemButton
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav" aria-label="To Do">
                        <Link to="/todo">
                            <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}
                            >
                                <ListItemIcon>
                                    <GradingIcon />
                                </ListItemIcon>
                                <ListItemText primary="To Do" />
                            </ListItemButton>
                        </Link>
                    </List>

                    

                    <List component="nav" aria-label="Configuration">
                        <Link to="/configuration">
                            <ListItemButton
                                selected={selectedIndex === 10}
                                onClick={(event) => handleListItemClick(event, 10)}
                            >
                                <ListItemIcon>
                                    <EngineeringIcon />
                                </ListItemIcon>
                                <ListItemText primary="Configuration" />
                            </ListItemButton>
                        </Link>
                    </List>

                    


                </Box>
            )
        } else if (menu === 2) {
            return (
                <Box sx={{ width: '100%', maxWidth: 320, bgcolor: '#333' }} >
                    <List component="nav" aria-label="Konfiguration">
                        <Link to="/konfiguration">
                            <ListItemButton
                                selected={selectedIndex === 8}
                                onClick={(event) => handleListItemClick(event, 8)}
                            >
                                <ListItemIcon>
                                    <PermDataSettingIcon />
                                </ListItemIcon>
                                <ListItemText primary="Konfiguration" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Box>
            )
        }
    }

    const logout = () => {
        cookies.remove('jwt', { path: '/' });
        navigate("/login");
    }

    return (

        <div>
            <div className={asideActive ? 'aside active' : 'aside'} >
                <div className='aside-header'>
                    <div className='logo'>
                        <img src={logo} alt="logo" />
                    </div>
                    <FormControl fullWidth>
                        <InputLabel >menu</InputLabel>
                        <Select
                            value={menu}
                            label="menu"
                            onChange={handleChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "center",
                                    horizontal: "center"
                                },
                                transformOrigin: {
                                    vertical: "center",
                                    horizontal: "center"
                                }
                            }}
                        >
                            <MenuItem value={1}>App</MenuItem>
                            {/* <MenuItem value={2}>Design</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                <div className='aside-body'>
                    <div className='aside-menu'>
                        {renderMenu()}
                    </div>
                    <div className='aside-bottom'>
                        <Button variant="contained" onClick={logout} fullWidth color="error" style={{ marginBottom: 4 }} startIcon={<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" fill="#fff"></path></svg>}>Log out</Button>
                        <p>Copyright © {new Date().getFullYear()} User Truck SRL</p>
                        <p>v 1.0.0</p>

                    </div>
                </div>
            </div>
            {tablet ? <div className={asideActive ? 'overlay active' : 'overlay'} onClick={asideToggle}></div> : null}
        </div>
    )
}

export default Aside