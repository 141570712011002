import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
// Components
import AppContainer from '../../Components/AppContainer'
import TimerReports from './TimerReports/TimerReports';


const Reports = ({ authorized, id }) => {

    const navigate = useNavigate();


    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        // loadPaymentMethod()
        setHeaderTitle("Reports")
        document.title = 'Usedtruck | Reports';
        setHeaderLinkTo('')

    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])


    // v.timer_info_payment === '2'
    //     ? totalCard = [...totalCard, parseInt(v.timer_info_cost)]
    //     : totalCash = [...totalCash, parseInt(v.timer_info_cost)]
    /* <td style={{ padding: '8px 0' }}><p>Total Card: {totalCard.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td>
    <td style={{ padding: '8px 0' }}><p>Total Cash: {totalCash.reduce(function (acc, val) { return acc + val; }, 0)}  €</p></td> */
    return (
        <AppContainer>
            <div className='padding'>
                <TimerReports id={id} />
            </div>
        </AppContainer>
    )
}

export default Reports