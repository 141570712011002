import React from 'react'

const Card = ({children, title, description, headerActionButton, fullWidth, radius, mb}) => {
    return (
        <div  className={fullWidth ? 'card card-fullwidth' : 'card'} style={{borderRadius: radius, marginBottom: mb}}>
            <div className='card-header' style={{borderTopRightRadius: radius, borderTopLeftRadius: radius}}>
                <div>
                    <h3>{title}</h3>
                    {description ? <p>{description}</p> : null}
                </div>
                {headerActionButton ? 
                    <div>{headerActionButton}</div>
                : null}
                
            </div>
            <div className='card-body'>
                <div className='card-body-content'>
                    {children}
                </div>
            </div>
            <div className='card-footer'>
                
            </div>
        </div>
    )
}

export default Card