import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

// Components
import AppContainer from '../../Components/AppContainer';
import AddedItems from './Components/AddedItems';
// Materila
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const TimerActive = ({ authorized, id }) => {

    const navigate = useNavigate();


    const [load, setLoad] = useState(false)
    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)


    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentTo, setPaymentTo] = useState([])
    const [activeTimer, setActiveTimer] = useState([])
    const [timerInfo, setTimerInfo] = useState([])

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Active timer")
        document.title = 'Usedtruck | Active timer';
        setHeaderLinkTo('/timer')
        loadActiveTimer()
        loadPaymentMethod()
        loadPaymentTo()
        setLoad(true)

    }, [setHeaderTitle, setHeaderLinkTo, authorized, navigate])


    const [timerTitle, setTimerTitle] = useState('')
    const [timerNote, setTimerNote] = useState('')

    const [cost, setCost] = useState(0)
    const [note, setNote] = useState('')

    const [type, setType] = useState('');
    const selectType = (event) => {
        setType(event.target.value);
    };

    const [payment, setPayment] = useState('')
    const selectPayment = (event) => {
        setPayment(event.target.value);
    };



    const loadPaymentMethod = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentMethod/get_payment_method.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentMethods(response.data)
        })
    }

    const loadPaymentTo = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/paymentToOption/get_payment_to.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setPaymentTo(response.data)
        })
    }

    const loadActiveTimer = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_get_active_date.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.data.length === 0) {
                navigate('/timer')
            }
            setActiveTimer(response.data)
            loadTimerInfo(response.data[0].timer_ID)
            setTimerTitle(response.data[0].title)
            setTimerNote(response.data[0].description)

        })
    }

    const loadTimerInfo = (activeID) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_get_dates_info.php`, {
            params: {
                user_ID: id,
                timer_ID: activeID
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTimerInfo(response.data)
        })
    }

    let formData = new FormData();

    const addItemInfo = () => {

        formData.append('cost', cost)
        formData.append('payto', payment)
        formData.append('paytype', type)
        formData.append('note', note)
        formData.append('timer_ID', activeTimer[0].timer_ID)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_post_date_info.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTimerInfo(activeTimer[0].timer_ID)
            setCost(0)
            setNote('')
            setType('')
            setPayment('')
        }).catch(function (response) {
            console.log(response);
        });

    }

    const deleteItemInfo = (id) => {
        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_delete_date_info.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            loadTimerInfo(activeTimer[0].timer_ID)
        }).catch(function (response) {
            console.log(response);
        });
    }

    const updateTimerInfo = () => {
        formData.append('timer_ID', activeTimer[0].timer_ID)
        formData.append('title', timerTitle)
        formData.append('note', timerNote)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/timer/api_update_date.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
        }).catch(function (response) {
            console.log(response);
        });

    }

    const stopTimer = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/timer/api_update_inactive.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            navigate('/timer')
        }).catch(error => console.log(error));;
    }

    return (
        <AppContainer>
            {load ?
                <div className='fading padding'>
                    <TextField type="number" fullWidth style={{ marginBottom: 16 }} label="Price" variant="outlined" onChange={(e) => setCost(e.target.value)} value={cost} />
                    <TextField fullWidth style={{ marginBottom: 16 }} label="Note" variant="outlined" onChange={(e) => setNote(e.target.value)} />

                    <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={type}
                            label="Type"
                            onChange={selectType}
                        >
                            {paymentMethods.map((val, i) => {
                                return <MenuItem key={i} value={val.timer_note_payment_ID}>{val.timer_note_payment_title}</MenuItem>
                            })}
                        </Select>
                    </FormControl >

                    <FormControl fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel>Pay to</InputLabel>
                        <Select
                            value={payment}
                            label="Pay to"
                            onChange={selectPayment}
                        >
                            {paymentTo.map((val, i) => {
                                return <MenuItem key={i} value={val.timer_note_types_ID}>{val.timer_note_types_title}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={() => addItemInfo()}>Add</Button>
                    <Divider style={{ marginBottom: 16, marginTop: 16 }}><p>Static</p></Divider>
                    <TextField value={timerTitle} fullWidth style={{ marginBottom: 16 }} label="Title" variant="outlined" onChange={(e) => setTimerTitle(e.target.value)} onBlur={() => updateTimerInfo()} />
                    <TextField value={timerNote} fullWidth style={{ marginBottom: 16 }} label="Description" variant="outlined" multiline rows={4} onChange={(e) => setTimerNote(e.target.value)} onBlur={() => updateTimerInfo()} />
                    <Button variant="contained" color="error" fullWidth onClick={stopTimer}>Stop timer</Button>


                    <Divider style={{ marginBottom: 16, marginTop: 16 }}><p>Added items</p></Divider>
                    {timerInfo.map((val, i) => {
                        return <AddedItems
                            key={i}
                            note={val.timer_info_desc}
                            paytype={val.timer_note_payment_title}
                            payto={val.timer_note_types_title}
                            cost={val.timer_info_cost}
                            onClick={() => deleteItemInfo(val.timer_info_ID)}
                        />
                    })}


                </div>
                : null}
        </AppContainer>
    )
}

export default TimerActive