import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { HeaderContext } from '../helper/Context';
// Materail
import IconButton from '@mui/material/IconButton';
// Icons
import MenuIcon from '@mui/icons-material/Menu';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const Header = ({ title, linkTo }) => {

    const { asideActive, setAsideActive } = useContext(HeaderContext)

    const asideToggle = () => {
        setAsideActive(!asideActive)
        console.log(asideActive)
    }

    let username = "Error";

    if (localStorage.getItem("User") !== null) {
        let userObject = localStorage.getItem('User');
        username = JSON.parse(userObject).Name;
    }

  
    return (
        <div className='header'>
            <div className='header-left'>
                {linkTo ?
                    <Link to={linkTo}>
                        <IconButton aria-label="backword">
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Link>
                    :
                    <IconButton aria-label="menu" onClick={asideToggle}>
                        <MenuIcon />
                    </IconButton>
                }
                <h1 id="headerTitle">{title}</h1>
            </div>
            <p id="HeaderLinkTo">{username}</p>
        </div>
    )
}

export default Header